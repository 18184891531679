import questions from './questions.json'

export const primaryColor = 'blue';

export const severityQuestions = questions.severityQuestions;
export const numberQuestions = questions.numberQuestions;
export const booleanQuestions = questions.booleanQuestions;
export const valueToSeverity = [
  'Not sure at all',
  'Several days',
  'More than half the days',
  'Nearly every day',
];
