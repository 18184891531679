import React from 'react';
import { Menu } from 'semantic-ui-react';
import { primaryColor } from './Constants.js';


class Footer extends React.Component {
  render() {
    return (
      <Menu id='footer' fixed='bottom' colors={primaryColor} size='small' widths={1} borderless inverted>
        <Menu.Item as='div'>Made with empathy by Aakash</Menu.Item>
      </Menu>
    )
  }
}

export default Footer;
