import React from 'react';
import {Responsive, Form, Grid, Header, Label} from 'semantic-ui-react';
import {severityQuestions, primaryColor} from './Constants.js';
import ResultModal from './ResultModal.js';
const {Row, Column} = Grid;

class GADForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      severityAnswers: {},
      age: 13,
      selfDiag: null,
      selfDiagError: false,
      answersErrors: Object.fromEntries(severityQuestions.map(q => [q, false])),
      loading: false,
      modalOpen: false,
      severity: null,
    };
  }

  onRadioChange = (_, d, i) => {
    const value = d.value;
    this.setState(prevState => {
      let answers = {...prevState.severityAnswers};
      answers[i] = value;
      return {severityAnswers: answers};
    });
  }

  onAgeChange = (_, {value}) => {
    this.setState({age: parseInt(value)});
  }

  onSelfDiagChange = (_, {value}) => {
    this.setState({selfDiag: value});
  }

  renderSeverityItem = (item) => {
    const value = this.state.severityAnswers[item] || null;
    const onChangeFn = (e, d) => this.onRadioChange(e, d, item);

    return (
      <React.Fragment key={item}>
        <Responsive
          as={Row}
          columns='equal'
          minWidth={Responsive.onlyTablet.minWidth}
        >
          <Column width={6}>
            <label> {item} </label>
            {this.state.answersErrors[item] && <Label size='tiny' basic color='red' pointing='left'>Must select an answer</Label>}
          </Column>
          <Column textAlign='center'>
            <Form.Radio
              value='zero'
              checked={value === 'zero'}
              onChange={onChangeFn}
            />
          </Column>
          <Column textAlign='center'>
            <Form.Radio
              value='one'
              checked={value === 'one'}
              onChange={onChangeFn}
            />
          </Column>
          <Column textAlign='center'>
            <Form.Radio
              value='two'
              checked={value === 'two'}
              onChange={onChangeFn}
            />
          </Column>
          <Column textAlign='center'>
            <Form.Radio
              value='three'
              checked={value === 'three'}
              onChange={onChangeFn}
            />
          </Column>
        </Responsive>
        <Responsive
          as={Row}
          {...Responsive.onlyMobile}
        >
          <Column width={12}>
            <Form.Field>
              <label>{item}</label>
              {this.state.answersErrors[item] && <Label size='tiny' basic color='red' pointing='above'>Must select an answer</Label>}
            </Form.Field>
            <Form.Field>
              <Form.Radio
                label='Not sure at all'
                value='zero'
                checked={value === 'zero'}
                onChange={onChangeFn}
              />
            </Form.Field>
            <Form.Field>
              <Form.Radio
                label='Several days'
                value='one'
                checked={value === 'one'}
                onChange={onChangeFn}
              />
            </Form.Field>
            <Form.Field>
              <Form.Radio
                label='Over half the days'
                value='two'
                checked={value === 'two'}
                onChange={onChangeFn}
              />
            </Form.Field>
            <Form.Field>
              <Form.Radio
                label='Nearly every day'
                value='three'
                checked={value === 'three'}
                onChange={onChangeFn}
              />
            </Form.Field>
          </Column>
        </Responsive>
      </React.Fragment>
    );
  }

  renderSeverityHeaders() {
    return (
      <>
        <Responsive
          as={Row}
          columns='equal'
          minWidth={Responsive.onlyTablet.minWidth}
        >
          <Column width={6} >
            <Header as='h3'> In the last 2 weeks how often have you felt...  </Header>
          </Column>
          <Column textAlign='center'> Not Sure At All </Column>
          <Column textAlign='center'> Several Days </Column>
          <Column textAlign='center'> Over Half the Days </Column>
          <Column textAlign='center'> Nearly Every Day </Column>
        </Responsive>
        <Responsive
          as={Row}
          {...Responsive.onlyMobile}
        >
          <Header as='h2'> In the last 2 weeks how often have you felt...  </Header>
        </Responsive>
      </>
    );
  }

  renderSeverityItems() {
    return severityQuestions.map(this.renderSeverityItem);
  }

  renderAgeField() {
    const {age} = this.state;
    return (
      <Row>
        <Column mobile={8} tablet={6} computer={4}>
          <Form.Input
            size='large'
            label='How old are you?'
            type='number'
            name='age'
            min={13}
            max={110}
            value={age.toString()}
            onChange={this.onAgeChange}
          />
        </Column>
      </Row>
    );
  }

  renderSelfDiagField() {
    const {selfDiag} = this.state;
    return (
      <Row>
        <Column width={16}>
          <Form.Field name='selfDiag' error={this.state.selfDiagError} inline >
            <label> Do you think you have anxiety? </label>
            {this.state.selfDiagError && <Label basic size='tiny' color='red' pointing='left'> Must select yes or no </Label>}
          </Form.Field>
          <Form.Field>
            <Form.Radio label='Yes' value='yes' checked={selfDiag === 'yes'} onChange={this.onSelfDiagChange} />
            <Form.Radio label='No' value='no' checked={selfDiag === 'no'} onChange={this.onSelfDiagChange} />
          </Form.Field>
        </Column>
      </Row>
    );
  }

  severityToInt = (s) => {
    if (s === 'zero')
      return 0;
    else if (s === 'one')
      return 1;
    else if (s === 'two')
      return 2;
    else if (s === 'three')
      return 3;
    return -1;
  };

  validateForm() {
    const selfDiagError = this.state.selfDiag === null;
    const answersErrors = {...this.state.answersErrors};
    severityQuestions.forEach((q) => {
      // question is not in answers object
      // question does not have an answer
      answersErrors[q] = !Object.keys(this.state.severityAnswers).includes(q);
    });
    this.setState({selfDiagError, answersErrors});
    return !selfDiagError && Object.values(answersErrors).every(v => !v);
  }

  onSubmit() {
    const valid = this.validateForm();
    if (!valid) {return;}

    const age = this.state.age; // no validation
    const selfDiag = (this.state.selfDiag === 'yes') ? true : false;
    const answers = Object.fromEntries(Object.entries(this.state.severityAnswers).map(([key, value]) => [key, this.severityToInt(value)]));

    this.setState({loading: true});
    fetch(`${window.env.apiUrl}/surveys/`, {
      mode: 'cors',
      method: 'post',
      body: JSON.stringify({age, selfDiag, answers, }),
    })
      .then(r => r.json())
      .then(r => {
        this.setState({
          loading: false,
          severity: r.severity,
          modalOpen: true,
        });
      })
      .catch(e => {
        this.setState({loading: false});
        console.error(e);
      });
  }

  render() {
    return (
      <Form onSubmit={this.onSubmit.bind(this)} className='main-content'>
        <Grid container >
          <Row>
            <Header as='h1'>Generalized Anxiety Disorder Survey</Header>
          </Row>
          {this.renderAgeField()}
          {this.renderSelfDiagField()}
          {/* <Responsive { ...Responsive.onlyComputer }> */}
          {this.renderSeverityHeaders()}
          {this.renderSeverityItems()}
          {/* </Responsive> */}
          <Form.Button id='submit' loading={this.state.loading} color={primaryColor} content='Submit' />
          <ResultModal
            open={this.state.modalOpen}
            onModalClose={() => this.setState({modalOpen: false})}
            severity={this.state.severity}
          />
        </Grid>
      </Form>
    );
  }
}

export default GADForm;
