import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Header } from 'semantic-ui-react';
import { severityQuestions, valueToSeverity } from './Constants.js';
import colors from './Colors.js';

class DashboardQuestions extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    const barData = [];
    const keys = valueToSeverity

    severityQuestions.forEach((q, i) => {
      const qData = { 'question': q, };
      keys.forEach(k => { qData[k] = 0; });
      qData['index'] = i.toString();
      barData.push(qData);
    });

    data.forEach(i => {
      severityQuestions.forEach((q, idx) => {
        const responseValue = i.answers[q];
        const responseString = valueToSeverity[responseValue];
        const barDataIdx = idx;
        barData[barDataIdx][responseString]++;
      });
    });

    return (
      <div className='barg'>
        <Header as='h2'>Response per question</Header>
        <ResponsiveBar
          data={barData}
          keys={keys}
          indexBy='question'
          layout='horizontal'
          margin={{ top: 30, right: 30, bottom: 160, left: 40 }}
          colors={(d) => colors[valueToSeverity.indexOf(d.id)]}
          axisLeft={{
            legend: 'questions',
            legendPosition: 'middle',
            legendOffset: -30,
            format: d => (severityQuestions.indexOf(d) + 1).toString(),
          }}
          axisBottom={{
            legend: 'count',
            legendPosition: 'middle',
            legendOffset: 30,
          }}
          legends={[
            {
              anchor: 'bottom-right',
              dataFrom: 'keys',
              direction: 'column',
              itemDirection: 'right-to-left',
              translateY: 150,
              itemWidth: 100,
              itemHeight: 30,
              symbolSize: 25,
              symbolShape: 'circle',
              effects: [{
                on: 'hover',
                style: { itemTextColor: '#000' }
              }]
            }
          ]}
        />
      </div>
    );
  }
}

export default DashboardQuestions;
