import React from 'react';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { Header } from 'semantic-ui-react';
import colors from './Colors';

class DashboardScatter extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderTooltip = ({ node }) => {
    return (
      <div
        style={{
          color: node.style.color,
          background: '#333',
          padding: '12px 16px',
        }}
      >
        <strong>
          {`Self diagnosis: ${node.data.serieId}`}
        </strong>
        <br />
        {`age: ${node.data.formattedX}`}
        <br />
        {`answers total: ${node.data.formattedY}`}
      </div>
    );
  }

  render() {
    const { data } = this.props;
    const scatterData = [
      {
        id: 'yes',
        data: [],
      },
      {
        id: 'no',
        data: [],
      }
    ];
    data.forEach(i => {
      const age = i.age;
      const diag = i.selfDiag;
      const total = Object.values(i.answers).reduce((a, b) => a + b, 0);
      if (diag) {
        scatterData[0].data.push({ x: age, y: total });
      } else {
        scatterData[1].data.push({ x: age, y: total });
      }
    });

    return (
      <div className='scatter'>
        <Header as='h2'> All Data </Header>
        <ResponsiveScatterPlot
          data={scatterData}
          margin={{ top: 30, right: 30, bottom: 160, left: 40 }}
          xFormat={function (e) { return e + " years" }}
          tooltip={this.renderTooltip}
          colors={d => d.serieId === scatterData[0].id ? colors[0] : colors[2]}
          axisBottom={{
            orient: 'bottom',
            legend: 'age',
            legendPosition: 'middle',
            legendOffset: 30
          }}
          axisLeft={{
            orient: 'left',
            legend: 'answers total',
            legendPosition: 'middle',
            legendOffset: -30
          }}
          legends={[
            {
              anchor: 'bottom-right',
              dataFrom: 'keys',
              direction: 'column',
              itemDirection: 'right-to-left',
              translateY: 120,
              itemWidth: 100,
              itemHeight: 30,
              symbolSize: 25,
              symbolShape: 'circle',
              effects: [{
                on: 'hover',
                style: { itemTextColor: '#000' }
              }]
            }
          ]}
        />
      </div>
    );
  }
}

export default DashboardScatter;
