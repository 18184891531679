import React from 'react';
import './App.css';
import Navbar from './Navbar.js';
import GADForm from './Form.js';
import About from './About.js';
import { Container } from 'semantic-ui-react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Dashboard from './Dashboard.js';
import Footer from './Footer';

function App() {
  return (
    <Router>
      <Container>
        <Navbar />
        <Switch>
          <Route exact path='/'>
            <GADForm />
          </Route>
          <Route exact path='/data'>
            <Dashboard />
          </Route>
          <Route exact path='/about'>
            <About />
          </Route>
        </Switch>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
