import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Header } from 'semantic-ui-react';
import colors from './Colors';

class DashboardBar extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {};
  }

  determineSeverity = (t) => {
    if (t >= 15) {
      return 'Severe';
    } else if (t >= 10) {
      return 'Moderate';
    } else if (t >= 5) {
      return 'Mild';
    }
    return 'None';
  }

  render() {
    const { data } = this.props;
    const severities = ['None', 'Mild', 'Moderate', 'Severe'];
    const barData = [];
    const keys = [
      'Think they have anxiety',
      'Do not think they have anxiety',
    ];

    severities.forEach(s => {
      barData.push({
        'severity': s,
        'Think they have anxiety': 0,
        'Do not think they have anxiety': 0,
      });
    });

    data.forEach(i => {
      const diag = i.selfDiag;
      const total = Object.values(i.answers).reduce((a, b) => a + b, 0);
      const sev = this.determineSeverity(total);
      const barDataIdx = severities.indexOf(sev);
      if (diag) {
        barData[barDataIdx]['Think they have anxiety']++;
      } else {
        barData[barDataIdx]['Do not think they have anxiety']++;
      }
    });

    return (
      <div className='barg'>
        <Header as='h2'>Count of severities</Header>
        <ResponsiveBar
          data={barData}
          keys={keys}
          groupMode='grouped'
          indexBy='severity'
          margin={{ top: 30, right: 30, bottom: 160, left: 40 }}
          colors={(d) => d.id === keys[0] ? colors[0] : colors[2]}
          axisBottom={{
            legend: 'severity',
            legendPosition: 'middle',
            legendOffset: 30,
          }}
          axisLeft={{
            legend: 'count',
            legendPosition: 'middle',
            legendOffset: -30,
          }}
          legends={[
            {
              anchor: 'bottom-right',
              dataFrom: 'keys',
              direction: 'column',
              itemDirection: 'right-to-left',
              translateY: 120,
              itemWidth: 100,
              itemHeight: 30,
              symbolSize: 25,
              symbolShape: 'circle',
              effects: [{
                on: 'hover',
                style: { itemTextColor: '#000' }
              }]
            }
          ]}
        />
      </div>
    );
  }
}

export default DashboardBar;
