import React from 'react';
import { Container, Header } from 'semantic-ui-react';

class About extends React.Component {
    render() {
        return (
            <Container className='main-content'>
                <Header as='h1'>About</Header>
                <p>
                    This site is a survey commonly known as the GAD7 survey
                    (generalized anxiety disorder 7-item scale). You can take
                    the survey and it'll report to you what degree of anxiety
                    you have (None, Mild, Moderate, Severe).
                </p>

                <Header as='h3'>Form</Header>
                <p>
                    You can take the questionnaire by filling out the form
                    under the form tab. The standard GAD7 survey does not
                    take into account an age or self diagnosis. That is more
                    to see trends and patterns of anxiety across different
                    age groups. Data that you submit is stored but it is
                    completely anonymized and cannot be traced to any
                    particular user.
                </p>

                <Header as='h3'>Data</Header>
                <p>
                    The data section shows a small dashboard that has a general overview of the data.
                </p>
            </Container>
        );
    }
}

export default About;