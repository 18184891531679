import React from 'react';
import {Modal, Button} from 'semantic-ui-react';

import {Link} from 'react-router-dom';

class ResultModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {open, onModalClose, severity} = this.props;
    return (
      <Modal open={open} >
        <Modal.Header>Results</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>{severity} </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button as={Link} to='/data' content='View data' />
          <Button content='Close' onClick={onModalClose} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ResultModal;
