import React from 'react';
import {Container, Header, Loader} from 'semantic-ui-react';
import DashboardScatter from './DashboardScatter.js';
import DashboardBar from './DashboardBar.js';
import DashboardQuestions from './DashboardQuestions.js';

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({loading: true});
    fetch(`${window.env.apiUrl}/surveys/`, {
      mode: 'cors',
    })
      .then(r => r.json())
      .then(r => {this.setState({data: r.Items, loading: false});})
      .catch(e => console.error(e));
  }

  renderCharts() {
    const {data, loading} = this.state;
    if (loading) {
      return (<Loader size='massive' active />);
    }
    return (
      <div>
        <DashboardQuestions data={data} />
        <DashboardScatter data={data} />
        <DashboardBar data={data} />
      </div>
    );

  }

  render() {
    return (
      <Container textAlign='center' className='main-content'>
        <Header as='h1'>Generalized Anxiety Disorder Data</Header>
        {this.renderCharts()}
      </Container>
    );
  }
}

export default Dashboard;
