import React from 'react';
import { Menu, Container } from 'semantic-ui-react';
import { primaryColor } from './Constants.js';

import { withRouter, Link } from 'react-router-dom';

class Navbar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleItemClick = (_, { id }) => this.setState({ activeItem: id })

  render() {
    const activeItem = this.props.location.pathname;
    return (
      <Menu id='header' fixed='top' color={primaryColor} size='large' inverted >
        <Container>
          <Menu.Item id='nav-header' as={Link} to='/' header>GAD7</Menu.Item>
          <Menu.Item id='nav-form' as={Link} to='/' active={activeItem === '/'}>Form</Menu.Item>
          <Menu.Item id='nav-data' as={Link} to='/data' active={activeItem === '/data'}>Data</Menu.Item>
          <Menu.Item id='nav-about' as={Link} to='/about' active={activeItem === '/about'}>About</Menu.Item>
        </Container>
      </Menu>
    )
  }
}

export default withRouter(Navbar);
